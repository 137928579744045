import { CsvBuilderFactory, CsvDocumentType } from "../../../../lib/csvBuilder/csvBuilderFactory";
import ServerRequest, { RequestError } from "../../../../lib/serverRequests/ServerRequest";
import { Utils } from "../../../../lib/utils/utils";
import { WorkPoint } from "../../companyProfilePage/types/types";
import ScaleReportsPageDataProvider from "../dataProvider/scaleReports.dataProvider";
import { RequestControllerResponse, GetReportsResponse, TimeRangeFilter } from "../types/types";

export class ScaleReportsController {
    private static dataProvider: ScaleReportsPageDataProvider = new ScaleReportsPageDataProvider();

    static getReports = async (timeRangeFilter: TimeRangeFilter): Promise<GetReportsResponse> => {
        let result: GetReportsResponse = {
            valid: true
        }

        const allFilters: Array<TimeRangeFilter> = [timeRangeFilter];

        try {
            result.scaleReports = await ServerRequest.getScaleReports(allFilters);
        } catch (err: RequestError | any) {
            result = ScaleReportsController.buildErrorResult(result, err)
            throw err;
        }

        return result;
    }

    public static async getCompanyWorkPoints(): Promise<WorkPoint[]> {
        return await this.dataProvider.getCompanyWorkPoints();
    }

    static buildErrorResult = (result: RequestControllerResponse, err: RequestError | any) => {
        result.valid = false;
        result.errorCode = (err as RequestError).statusCode;
        result.errorMessage = (err as RequestError).message;
        return result;
    }

    static exportCsvData = (scaleReports: Array<any>, totalWeight: number, workPoints: Map<string, string>): void => {
        let headers = ["Nr. vehicul","Nr remorca","Nume sofer","Nr. tichet","Data intrare","Data iesire","Greutate Intrare","Greutate Iesire","Greutate Neta","Furnizor","Client","Transportator","Produs","Document insotitor","Nr. Document Insotitor","Operator","Cod UIT","Loc de descarcare","Status","Punct de lucru"];
        let additionalInfo = {
            totalWeight: totalWeight,
            workPoints: workPoints
        };

        let factory = new CsvBuilderFactory();
        let csvBuilder = factory.getCsvBuilder(CsvDocumentType.ScaleReportsCsv);
        let { month, day, hour, minute } = Utils.getDateElements(new Date(Date.now()));
        let date = `${day}_${month}_${hour}_${minute}`;

        csvBuilder.setHeaders(headers);
        csvBuilder.setFileName(`Rapoarte_cantarire_EvertechPortal_${date}.csv`);
        csvBuilder.setContentElements(scaleReports);
        csvBuilder.setAdditionalInfo(additionalInfo);
        csvBuilder.downloadCsvFile();
    }
}