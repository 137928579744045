import { ChangeEvent, Component, FormEvent, ReactElement } from "react";
import CompanyProfilePageController from "./controller/companyProfilePage.controller";
import { ClientDetailedAddress, Company } from "../../../lib/types";
import searchIcon from '../../../images/icons8-search-24.png';
import editImg from '../../../images/edit-icon.png';
import './companyProfilePage.styles.css';
import { Utils } from "../../../lib/utils/utils";
import { WorkPoint } from "./types/types";
import { ETFileUploadForm, ETFileUploadFormats } from "../../ETComponents/ETFileUploadForm/etfileuploadform.component";
import { Buffer } from 'buffer';
import { dispatchRequestErrorEvent } from "../../../lib/events/requestErrorEvent";
import { dispatchPopupEvent } from "../../../lib/events/popupEvent";
import { PopupButtonTypes } from "../../ETComponents/ETPopup/constants";

interface CompanyProfilePageState {
    companyName: string;
    companyCui: string;
    companyRegComNo: string;
    companyAddress: string;
    companyIban: string;
    companyBankName: string;
    companyDetailedAddress: ClientDetailedAddress;
    vatOnPayment: boolean;
    vatPayer: boolean;
    workPoints: Array<WorkPoint>;
    editableWorkPoint: WorkPoint | undefined;
    workPointName: string;
    companyLogoData: any;
    companyLogoDataType: string;
    companyLogoLoading: boolean;
}

interface CompanyProfilePageProps {

}

class CompanyProfilePage extends Component<CompanyProfilePageProps, CompanyProfilePageState> {
    private controller: CompanyProfilePageController = new CompanyProfilePageController();
    constructor(props: any) {
        super(props);

        this.state = {
            companyName: '',
            companyCui: '',
            companyRegComNo: '',
            companyAddress: '',
            companyIban: '',
            companyBankName: '',
            companyDetailedAddress: {
                countyCode: '',
                postalCode: '',
                cityName: '',
                streetName: ''
            },
            vatOnPayment: false,
            vatPayer: false,
            workPoints: [],
            editableWorkPoint: undefined,
            workPointName: '',
            companyLogoData: null,
            companyLogoDataType: '',
            companyLogoLoading: false
        };

        // bind here events handlers
        this.handleCompanyPropertyChanged = this.handleCompanyPropertyChanged.bind(this);
        this.saveCompanyData = this.saveCompanyData.bind(this);
        this.searchCompanyToAnaf = this.searchCompanyToAnaf.bind(this);
        this.handleVatOnPaymentCheck = this.handleVatOnPaymentCheck.bind(this);
        this.handleVatPayerCheck = this.handleVatPayerCheck.bind(this);
        this.checkCompanyFields = this.checkCompanyFields.bind(this);
        this.showWorkPointForm = this.showWorkPointForm.bind(this);
        this.handleCancelWorkPointClicked = this.handleCancelWorkPointClicked.bind(this);
        this.handleModifyWorkPointClicked = this.handleModifyWorkPointClicked.bind(this);
        this.onCompanyLogoChange = this.onCompanyLogoChange.bind(this);
        this.deleteCompanyLogo = this.deleteCompanyLogo.bind(this);
    }

    async componentDidMount(): Promise<void> {
        await this.loadCompanyData();
        await this.loadCompanyWorkPoints();
    }

    private async loadCompanyData() {
        try {
            const companyData: Company = await this.controller.getCompanyData();
            this.setState({
                companyName: companyData.companyName,
                companyCui: companyData.companyCui,
                companyRegComNo: companyData.generalCompanyData && companyData.generalCompanyData.regComCode ? companyData.generalCompanyData.regComCode : '',
                companyAddress: companyData.generalCompanyData && companyData.generalCompanyData.socialAddress ? companyData.generalCompanyData.socialAddress : '',
                companyIban: companyData.generalCompanyData && companyData.generalCompanyData.iban ? companyData.generalCompanyData.iban : '',
                companyBankName: companyData.generalCompanyData && companyData.generalCompanyData?.bankName ? companyData.generalCompanyData.bankName : '',
                companyDetailedAddress: {
                    countyCode: companyData.generalCompanyData?.detailedAddress && companyData.generalCompanyData?.detailedAddress.countyCode ? companyData.generalCompanyData?.detailedAddress.countyCode : "",
                    postalCode: companyData.generalCompanyData?.detailedAddress && companyData.generalCompanyData?.detailedAddress.postalCode ? companyData.generalCompanyData?.detailedAddress.postalCode : "",
                    cityName: companyData.generalCompanyData?.detailedAddress && companyData.generalCompanyData?.detailedAddress.cityName ? companyData.generalCompanyData?.detailedAddress.cityName : "",
                    streetName: companyData.generalCompanyData?.detailedAddress && companyData.generalCompanyData?.detailedAddress.streetName ? companyData.generalCompanyData?.detailedAddress.streetName : ""
                },
                vatOnPayment: companyData.generalCompanyData && companyData.generalCompanyData.vatOnPayment ? companyData.generalCompanyData.vatOnPayment : false,
                companyLogoData: companyData.logo,
                companyLogoDataType: companyData.logoDataType,
                vatPayer: companyData.generalCompanyData ? companyData.generalCompanyData.vatPayer : false
            });
        } catch (err: any) {
            dispatchRequestErrorEvent(err);
        }
    }

    private async loadCompanyWorkPoints() {
        try {
            const workPoints: WorkPoint[] = await this.controller.getCompanyWorkPoints();
            this.setState({
                workPoints: workPoints
            });
        } catch (err: any) {
            dispatchRequestErrorEvent(err);
        }
    }

    private handleVatOnPaymentCheck(event: ChangeEvent<HTMLInputElement>) {
        this.setState({
            ...this.state,
            vatOnPayment: event.target.checked
        })
    }

    private handleVatPayerCheck(event: ChangeEvent<HTMLInputElement>) {
        this.setState({
            ...this.state,
            vatPayer: event.target.checked
        })
    }

    private handleCompanyPropertyChanged(event: ChangeEvent<HTMLInputElement>) {
        const { name, value } = event.target;

        this.setState({ [name as keyof CompanyProfilePageState]: value }  as unknown as Pick<CompanyProfilePageState, keyof CompanyProfilePageState>);
    }

    private async saveCompanyData(event: FormEvent) {
        // stop form event from doint it's thing (refresh the page / clear form / etc)
        event.preventDefault();
        
        const { companyRegComNo, companyAddress, companyIban, companyBankName, companyDetailedAddress, vatOnPayment, vatPayer } = this.state;

        if(!this.checkCompanyFields()) {
            dispatchPopupEvent('Atentie', 'Va rugam completati toate campurile obligatorii');
            return;
        }

        this.checkCompanyFields();
        await this.controller.updateCompanyData(companyRegComNo, companyAddress, companyIban, companyBankName, companyDetailedAddress, vatOnPayment, vatPayer);
        dispatchPopupEvent('Success', 'Datele au fost salvate cu success');
    }

    private checkCompanyFields() {
        const { companyRegComNo, companyAddress } = this.state;

        //companyIban and companyBankName fields are optional
        return Utils.isStringNonEmpty(companyRegComNo) && Utils.isStringNonEmpty(companyAddress);
    }

    private async searchCompanyToAnaf() {
        const companyCui = this.state.companyCui.replace('RO', '');
        try {
            const companyData = await Utils.getCompanyDataFromAnaf(companyCui);

            if (!companyData) {
                dispatchPopupEvent('Sigur ai scris corect?', `Nu a fost gasita in baza de date ANAF nicio societate juridica cu C.I.F.: ${companyCui}.`);
                return;
            }

            const { adresa, iban, nrRegCom } = companyData.date_generale;
            const { scod_JudetAuto, scod_Postal, sdenumire_Localitate, sdenumire_Strada } = companyData.adresa_sediu_social;
            const vatPayer = companyData.inregistrare_scop_Tva.scpTVA;
            const detailedAddress = {
                countyCode: scod_JudetAuto,
                postalCode: scod_Postal,
                cityName: sdenumire_Localitate,
                streetName: sdenumire_Strada,
            };

            this.setState({
                companyRegComNo: nrRegCom,
                companyAddress: adresa,
                companyIban: iban,
                companyDetailedAddress: detailedAddress,
                vatPayer: vatPayer
            });

        } catch (err: any) {
            dispatchPopupEvent('Eroare', err.message);
        }
    }

    private showWorkPointForm(workPoint: WorkPoint) {
        this.setState({
            editableWorkPoint: workPoint,
            workPointName: workPoint.name!
        })
    }

    private handleCancelWorkPointClicked() {
        this.setState({
            editableWorkPoint: undefined
        })
    }

    private async handleModifyWorkPointClicked() {
        if(this.state.workPointName === undefined || this.state.workPointName.trim() === "") {
            dispatchPopupEvent('Atentie', 'Va rugam sa completati numele punctului de lucru.');
            return;
        }

        const workPoints = this.state.workPoints;

        for (const workPoint of workPoints) {
            if(workPoint.name! === this.state.workPointName && workPoint._id !== this.state.editableWorkPoint!._id) {
                dispatchPopupEvent('Atentie', 'Numele punctului de lucru este deja folosit.');
                return;
            }

            if(workPoint._id === this.state.editableWorkPoint!._id && workPoint.name! === this.state.workPointName) {
                this.setState({
                    editableWorkPoint: undefined,
                    workPointName: ""
                });
                return;
            }
        }

        workPoints.forEach((workPoint) => {
            if(workPoint._id === this.state.editableWorkPoint!._id) {
                workPoint.name = this.state.workPointName;
            }
        });

        try {
            await this.controller.updateCompanyWorkPoint(this.state.editableWorkPoint!)
        } catch (err: any) {
            dispatchRequestErrorEvent(err);
        }

        this.setState({
            workPoints: workPoints,
            editableWorkPoint: undefined,
            workPointName: ""
        });
    }

    private async onCompanyLogoChange(fileData: any, fileType: string, errorMessage: string) {
        if(errorMessage !== "") {
            dispatchPopupEvent('Eroare', errorMessage, false, PopupButtonTypes.OK);
            return;
        }

        try {
            this.setState({
                companyLogoLoading: true
            });
            const buffer = Buffer.from(fileData).toString("base64");
            await this.controller.uploadCompanyLogo(buffer, fileType);

            this.setState({
                companyLogoData: null,
                companyLogoDataType: '',
                companyLogoLoading: false
            }, () => {
                this.setState({
                    companyLogoData: buffer,
                    companyLogoDataType: fileType
                })
            });
        } catch (err: any) {
            this.setState({
                companyLogoLoading: false
            })

            dispatchRequestErrorEvent(err);
        }
    }

    private async deleteCompanyLogo() {

        dispatchPopupEvent('Atentie!', `Sigur doriti sa stergeti imaginea?`, false, PopupButtonTypes.YES_NO, async () => {
            try {
                this.setState({
                    companyLogoLoading: true
                });
                await this.controller.deleteCompanyLogo();
    
                this.setState({
                    companyLogoData: null,
                    companyLogoDataType: '',
                    companyLogoLoading: false
                });
            } catch (err: any) {
                this.setState({
                    companyLogoLoading: false
                });
                dispatchRequestErrorEvent(err);
            }
        });
    }

    render() {
        const tableContent: Array<ReactElement | void> = this.state.workPoints.map((workPoint) => {
            return (
                <tr key={workPoint._id} id="work-points-table-body-row">
                    <td id="work-points-table-cell">
                        {workPoint.name}
                    </td>
                    <td id="work-points-table-cell">
                        {workPoint.address}
                    </td>
                    <td>
                        <img id='work-point-edit-icon' className='work-point-edit-icon' src={editImg} alt='modify' onClick={() => this.showWorkPointForm(workPoint)}/>
                    </td>
                </tr>
            );
        });
        

        return (
            <div className='etportal-page-container'>
                <h1 className='module-title'>Profil companie</h1>

                <div className='form-container'>
                    <form id='company-data-form'>
                        <div className='et-form-group'>
                            <label className='et-form-label'>Nume companie:</label>
                            <input
                                id='company-name-input'
                                className='et-form-input'
                                name='companyName'
                                value={this.state.companyName}
                                onChange={this.handleCompanyPropertyChanged}
                                required
                                disabled
                            />
                        </div>
                        <div className='et-form-group' id='div-with-image'>
                            <label className='et-form-label'>Cui companie:</label>
                            <input
                                id='company-cui-input'
                                className='et-form-input'
                                name='companyCui'
                                value={this.state.companyCui}
                                onChange={this.handleCompanyPropertyChanged}
                                required
                                disabled
                            />
                            <div id='search-image-container'>
                                <img id='search-anaf-img' src={searchIcon} alt='print' onClick={this.searchCompanyToAnaf}/>
                            </div>
                            
                        </div>
                        <div className='et-form-group'>
                            <label className='et-form-label'>Numar reg. com.:</label>
                            <input
                                id='company-regCom-input'
                                className='et-form-input'
                                name='companyRegComNo'
                                value={this.state.companyRegComNo}
                                onChange={this.handleCompanyPropertyChanged}
                                required
                            />
                        </div>
                        <div className='et-form-group'>
                            <label className='et-form-label'>Adresa sediu companie:</label>
                            <input
                                id='company-address-input'
                                className='et-form-input'
                                name='companyAddress'
                                value={this.state.companyAddress}
                                onChange={this.handleCompanyPropertyChanged}
                                required
                            />
                        </div>
                        <div className='et-form-group'>
                            <label className='et-form-label'>IBAN:</label>
                            <input
                                id='company-iban-input'
                                className='et-form-input'
                                name='companyIban'
                                value={this.state.companyIban}
                                onChange={this.handleCompanyPropertyChanged}
                                required
                            />
                        </div>
                        <div className='et-form-group'>
                            <label className='et-form-label'>Nume banca:</label>
                            <input
                                id='company-bank-name-input'
                                className='et-form-input'
                                name='companyBankName'
                                value={this.state.companyBankName}
                                onChange={this.handleCompanyPropertyChanged}
                                required
                            />
                        </div>
                        <div className='et-form-group'>
                            <label className='et-form-label'>TVA la incasare:</label>
                            <input
                                id='company-vat-on-payment-checkbox'
                                name='vatOnPayment'
                                type="checkbox"
                                checked={this.state.vatOnPayment}
                                onChange={this.handleVatOnPaymentCheck}
                                required
                            />
                        </div>
                        <div className='et-form-group'>
                            <label className='et-form-label'>Platitor de TVA:</label>
                            <input
                                id='company-vat-payer-checkbox'
                                name='vatPayer'
                                type="checkbox"
                                checked={this.state.vatPayer}
                                onChange={this.handleVatPayerCheck}
                                required
                            />
                        </div>
                        <input 
                            className='et-button' 
                            id='create-user-button'
                            type='submit'
                            value='Actualizeaza date'
                            onClick={this.saveCompanyData}
                        ></input>
                    </form>
                </div>

                <div>
                    <div className="company-logo-title ">
                        Logo companie
                    </div>
                    <ETFileUploadForm
                        allowMultipleFiles={false}
                        acceptedFormats={ETFileUploadFormats.IMAGES}
                        previewData={this.state.companyLogoData}
                        previewDataType={this.state.companyLogoDataType}
                        isLoading={this.state.companyLogoLoading}
                        onChange={this.onCompanyLogoChange}
                        deleteUpload={this.deleteCompanyLogo}
                    />
                </div>

                <div className="work-points-table-container">
                    
                    <div id="work-points-table-title">Puncte de lucru</div>

                    {
                        this.state.editableWorkPoint !== undefined ? 
                        <div id="work-point-form">
                            <div id="work-point-form-inputs">
                                <div id='work-point-name-container' className='work-point-header-data'>
                                    <label id='name-label' className='work-point-header-label'>Nume:</label>
                                    <input
                                        id='name-input'
                                        className='et-form-input work-point-text-input'
                                        value={this.state.workPointName}
                                        required
                                        name='workPointName'
                                        onChange={this.handleCompanyPropertyChanged}
                                    />
                                </div>
                                <div id='work-point-address-container' className='work-point-header-data'>
                                    <label id='address-label' className='work-point-header-label'>Adresa:</label>
                                    <input
                                        id='address-input'
                                        disabled
                                        className='et-form-input work-point-text-input'
                                        value={this.state.editableWorkPoint.address}
                                        required
                                        name='workPointAddress'
                                    />
                                </div>
                            </div>
                            <input
                                id='modify-work-point-button'
                                className='et-button'
                                type='submit'
                                value='Modifica'
                                onClick={this.handleModifyWorkPointClicked}
                            ></input>
                            <input
                                id='cancel-work-point-button'
                                className='et-button-danger'
                                type='submit'
                                value='Renunta'
                                onClick={this.handleCancelWorkPointClicked}
                            ></input>
                        </div>
                        :
                        <></>
                    }

                    <table id="work-points-table">
                        <thead>
                            <tr id="work-points-table-head-row">
                                <th id="work-points-table-head-cell">
                                    Nume
                                </th>
                                <th id="work-points-table-head-cell">
                                    Adresa
                                </th>
                                <th id="work-points-table-head-cell">
                                    Actiuni
                                </th>
                            </tr>
                        </thead>
                        <tbody id="work-points-table-body">
                            {tableContent}
                        </tbody>
                    </table>

                </div>

            </div>
        )
    }
}

export default CompanyProfilePage;
