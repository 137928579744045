import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ServerRequest, { RequestError } from '../../../lib/serverRequests/ServerRequest';
import { modifyUserEmail, modifyUserJobTitle, modifyUserName, modifyUserPhone } from '../../../redux/actions/auth';
import { displayPopup } from '../../../redux/actions/popup';
import { RootState } from '../../../redux/types';
import { PopupButtonTypes } from '../../ETComponents/ETPopup/constants';
import ProfileRow from './innerComponents/profileRow/profileRow.component';
import './profilePage.styles.css';
import { ProfileFormAlert, ProfileRowData } from './types';

interface ProfilePageState {
    nameAlert: ProfileFormAlert;
    emailAlert: ProfileFormAlert;
    passwordAlert: ProfileFormAlert;
    phoneAlert:ProfileFormAlert,
    jobTitleAlert:ProfileFormAlert
}

const ProfilePage = () => {
    const defaultState: ProfilePageState = {
        nameAlert: {
            visible: false,
            type: 'success',
            message: ''
        },
        emailAlert: {
            visible: false,
            type: 'success',
            message: ''
        },
        passwordAlert: {
            visible: false,
            type: 'success',
            message: ''
        },
        phoneAlert: {
            visible: false,
            type: 'success',
            message: ''
        },
        jobTitleAlert: {
            visible: false,
            type: 'success',
            message: ''
        }
    }

    const [state, setState] = useState<ProfilePageState>(defaultState)

    const dispatch = useDispatch();
    const userName = useSelector((state: RootState) => state.auth.user.name);
    const userId = useSelector((state: RootState) => state.auth.user.id);
    const userEmail = useSelector((state: RootState) => state.auth.user.email);
    const userPhone = useSelector((state: RootState) => state.auth.user.phone);
    const userJobTitle = useSelector((state: RootState) => state.auth.user.jobTitle);

    const displayAlert = (alertSection: string, alertType: string, alertMessage: string): void => {
        setState({
            ...state,
            [alertSection]: {
                visible: true,
                type: alertType,
                message: alertMessage
            }
        });

        setTimeout(() => {
            setState(defaultState)
        }, 5000);
    }

    const submitNameForm = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        let modificationSuccessful = true;

        const userNameInput = document.getElementById('name-input') as HTMLInputElement;
        
        const requestBody = {
            name: userNameInput.value
        };

        try {
            await ServerRequest.updateUser(requestBody, userId)
            dispatch(modifyUserName(userNameInput.value));
        } catch (err: RequestError | any) {
            modificationSuccessful = false;

            if ((err as RequestError).statusCode === 401) {
                dispatch(displayPopup('OOps, looks like session problems!', (err as RequestError).message, PopupButtonTypes.OK, true));
            } else {
                displayAlert('nameAlert', 'danger', 'Internal Server Error. Please try again later.');
            }
        }

        if (modificationSuccessful) displayAlert('nameAlert', 'success', 'Numele a fost modificat cu succes.');
    }

    const submitEmailForm = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        let modificationSuccessful = true;

        const userEmailInput = document.getElementById('email-input') as HTMLInputElement;
        
        const requestBody = {
            email: userEmailInput.value
        };

        try {
            await ServerRequest.updateUser(requestBody, userId);
            dispatch(modifyUserEmail(userEmailInput.value));
        } catch (err: RequestError | any) {
            modificationSuccessful = false;

            if ((err as RequestError).statusCode === 401) {
                dispatch(displayPopup('OOps, looks like session problems!', (err as RequestError).message, PopupButtonTypes.OK, true));
            } else {
                displayAlert('emailAlert', 'danger', 'Internal Server Error. Please try again later.');
            }
        }

        if (modificationSuccessful) displayAlert('emailAlert', 'success', 'Email-ul a fost modificat cu succes.');
    }

    const submitPhoneForm = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        let modificationSuccessful = true;

        const userPhoneInput = document.getElementById('phone-input') as HTMLInputElement;
        
        const requestBody = {
            phone: userPhoneInput.value
        };

        try {
            await ServerRequest.updateUser(requestBody, userId);
            dispatch(modifyUserPhone(userPhoneInput.value));
        } catch (err: RequestError | any) {
            modificationSuccessful = false;

            if ((err as RequestError).statusCode === 401) {
                dispatch(displayPopup('OOps, looks like session problems!', (err as RequestError).message, PopupButtonTypes.OK, true));
            } else {
                displayAlert('phoneAlert', 'danger', 'Internal Server Error. Please try again later.');
            }
        }

        if (modificationSuccessful) displayAlert('phoneAlert', 'success', 'Numarul de telefon a fost modificat cu succes.');
    }

    const submitJobTitleForm = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        let modificationSuccessful = true;

        const userJobTitleInput = document.getElementById('job-title-input') as HTMLInputElement;
        
        const requestBody = {
            jobTitle: userJobTitleInput.value
        };

        try {
            await ServerRequest.updateUser(requestBody, userId);
            dispatch(modifyUserJobTitle(userJobTitleInput.value));
        } catch (err: RequestError | any) {
            modificationSuccessful = false;

            if ((err as RequestError).statusCode === 401) {
                dispatch(displayPopup('OOps, looks like session problems!', (err as RequestError).message, PopupButtonTypes.OK, true));
            } else {
                displayAlert('jobTitleAlert', 'danger', 'Internal Server Error. Please try again later.');
            }
        }

        if (modificationSuccessful) displayAlert('jobTitleAlert', 'success', 'Functia a fost modificata cu succes.');
    }

    const submitPasswordForm = async (e: React.SyntheticEvent) => {
        e.preventDefault();

        let modificationSuccessful = true;
        let validInput = true;

        const passwordInput1 = document.getElementById('password1-input') as HTMLInputElement;
        const passwordInput2 = document.getElementById('password2-input') as HTMLInputElement;

        validInput = passwordInput1.value === passwordInput2.value;

        if (!validInput) {
            displayAlert('passwordAlert', 'danger', 'Parolele nu se potrivesc. Va rugam sa reincercati.')
            return;
        }

        const requestBody = {
            password: passwordInput1.value
        };

        try {
            await ServerRequest.updateUser(requestBody, userId);
        } catch (err: RequestError | any) {
            modificationSuccessful = false;

            if ((err as RequestError).statusCode === 401) {
                dispatch(displayPopup('OOps, looks like session problems!', (err as RequestError).message, PopupButtonTypes.OK, true));
            } else {
                displayAlert('passwordAlert', 'danger', 'Internal Server Error. Please try again later.');
            }
        }

        if (modificationSuccessful) {
            displayAlert('passwordAlert', 'success', 'Parola a fost modificata cu succes.');
        }
    }

    const nameGroupData: ProfileRowData = {
        formGroupData: [
            {
                labelValue: 'Modifica nume',
                inputId: 'name-input',
                inputName: 'name',
                inputType: 'text',
                inputDefaultValue: userName
            }
        ],
        alert: state.nameAlert
    };

    const emailGroupData: ProfileRowData = {
        formGroupData: [
            {
                labelValue: 'Modifica email',
                inputId: 'email-input',
                inputName: 'email',
                inputType: 'text',
                inputDefaultValue: userEmail
            }
        ],
        alert: state.emailAlert
    };

    const passwordGroupData: ProfileRowData = {
        formGroupData: [
            {
                labelValue: 'Creaza parola noua',
                inputId: 'password1-input',
                inputName: 'password1',
                inputType: 'password',
                inputDefaultValue: ''
            }, 
            {
                labelValue: 'Confirma parola noua',
                inputId: 'password2-input',
                inputName: 'password2',
                inputType: 'password',
                inputDefaultValue: ''
            }
        ],
        alert: state.passwordAlert
    };

    const phoneGroupData: ProfileRowData = {
        formGroupData: [
            {
                labelValue: 'Modifica telefon',
                inputId: 'phone-input',
                inputName: 'phone',
                inputType: 'text',
                inputDefaultValue: userPhone
            }
        ],
        alert: state.phoneAlert
    };

    const jobTitleGroupData: ProfileRowData = {
        formGroupData: [
            {
                labelValue: 'Modifica functia',
                inputId: 'job-title-input',
                inputName: 'job-title',
                inputType: 'text',
                inputDefaultValue: userJobTitle
            }
        ],
        alert: state.jobTitleAlert
    };


    return (
        <div className='etportal-page-container'>
            <h1 className='module-title'>Profilul meu</h1>
            <div className='et-section-no-shadow' id='account-data-section'>
                <h3 className='et-section-title'>Datele contului</h3>
                <ProfileRow title='Nume' buttonText='Schimba nume' displayValue={userName} detailsData={nameGroupData} submitForm={submitNameForm} />
                <ProfileRow title='Email' buttonText='Schimba email' displayValue={userEmail} detailsData={emailGroupData} submitForm={submitEmailForm} />
                <ProfileRow title='Parola' buttonText='Schimba parola' displayValue={'&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;'} detailsData={passwordGroupData} submitForm={submitPasswordForm} />
                <ProfileRow title='Telefon' buttonText='Schimba telefon' displayValue={userPhone} detailsData={phoneGroupData} submitForm={submitPhoneForm} />
                <ProfileRow title='Functia' buttonText='Schimba functia' displayValue={userJobTitle} detailsData={jobTitleGroupData} submitForm={submitJobTitleForm} />
            </div>
        </div>
    )
}

export default ProfilePage;