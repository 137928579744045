import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import './loginPage.styles.css';

import { Alert } from '../../lib/Alert/alert.component';
import { login } from '../../../redux/actions/auth';
import loginPicture from '../../../images/loginPagePic.jpg';

interface LoginState {
	email: string;
	password: string;
}

const LoginPage = () => {
	const [formData, setFormData] = useState<LoginState>({
		email: '',
		password: '',
	});

	const { email, password } = formData;

	const dispatch = useDispatch();

	const onChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
		setFormData({ ...formData, [e.target.name]: e.target.value });

	const onSubmit = (e: React.SyntheticEvent) => {
		e.preventDefault();
		dispatch(login(email, password));
	};

	return (
		<div id='login-page-container'>
			<div id='image-div'>
				<img id='login-image' src={loginPicture} alt='login-page-cover' />
			</div>
			<div id='login-panel'>
				<h1 id='login-title'>Welcome to EverTech Portal</h1>
				<h2 id='login-subtitle'>Enter your credentials below:</h2>
				<form id='login-form' onSubmit={(e) => onSubmit(e)}>
					<div className='form-group'>
						<label>Email address:</label>
						<input
							className='login-form-input'
							type='text'
							name='email'
							value={email}
							placeholder='Email...'
							onChange={(e) => onChange(e)}
							required
						/>
					</div>
					<div className='form-group'>
						<label>Password:</label>
						<input
							className='login-form-input'
							type='password'
							name='password'
							value={password}
							placeholder='Password...'
							onChange={(e) => onChange(e)}
							required
						/>
					</div>
					<Alert />
					<input id='login-button' type='submit' value='LOG IN' />
				</form>
			</div>
		</div>
	);
};

export default LoginPage;
