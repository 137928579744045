export const CONSTANTS = {
    MODULES: {
        PROFILE_PAGE: 'profile_page',
        SUPERADMIN_PAGE: 'superadming_page',
        SCALE_REPORTS_PAGE: 'scale_reports_page',
        PURCHASE_ORDERS_PAGE: 'purchase_orders_page',
        WASTE_MANAGEMENT_PAGE: 'waste_management_page',
        ANAF_OAUTH_PAGE: 'anaf_ouath_page',
        INVOICE_PAGE: 'invoice_page',
        COMPANY_PROFILE_PAGE: 'company_profile_page',
    },
    USER_ROLES: {
        ADMIN: 'admin',
        SUPERADMIN: 'superadmin'
    },
    FEATURES: {
        PURCHASE_ORDERS: 1000,
        WASTE_MANAGEMENT: 1001,
        SPV_INTEGRATION: 1002
    }
}