import { ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import seeMoreIcon from '../../../images/view.png';
import { RequestError } from '../../../lib/serverRequests/ServerRequest';
import { PurchaseOrder } from '../../../lib/types';
import { displayPopup } from '../../../redux/actions/popup';
import { PopupButtonTypes } from '../../ETComponents/ETPopup/constants';
import PurchaseOrdersSidePanel from '../../lib/purchaseOrdersSidePanel/purchaseOrdersSidePanel.component';
import './purchaseOrdersPage.styles.css'
import { PurchaseOrderRequest } from '../../../lib/serverRequests/purchaseOrderRequests/PurchaseOrderRequest';
import { Utils } from '../../../lib/utils/utils';

interface PurchaseOrdersState {
    purchaseOrders: Array<PurchaseOrder>;
    selectedOrder: PurchaseOrder | null;
}

const PurchaseOrdersPage = () => {
    const dispatch = useDispatch();
    const [data, setData] = useState<PurchaseOrdersState>({
        purchaseOrders: [],
        selectedOrder:  null
    });

    useEffect(() => {
        const getData = async () => {
            await getPurchaseOrders();
        }

        getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getPurchaseOrders = async () => {
        let purchaseOrdersArray: Array<PurchaseOrder> = [];
        try {
            purchaseOrdersArray = await PurchaseOrderRequest.getPurchaseOrders();
        } catch (err: RequestError | any) {
            if ((err as RequestError).statusCode === 401) {
                dispatch(displayPopup('OOps, looks like session problems!', (err as RequestError).message, PopupButtonTypes.OK, true));
            } else {
                dispatch(displayPopup('Error', 'Internal Server Error', PopupButtonTypes.OK, false));
            }
        }

        const arrayOrderSort: {[key:string]: number} = {
            "Loading Truck": 0,
            "Sending Documents": 1,
            "New": 2,
            "Cancelled": 3,
            "Complete": 4
        };
    
        purchaseOrdersArray.sort((a, b) => arrayOrderSort[a.status] - arrayOrderSort[b.status]);
        
        setData({
            ...data,
            purchaseOrders: purchaseOrdersArray
        });
    }

    const showDetailsPanel = (orderId: string) => {
        const selectedOrder = data.purchaseOrders.filter((order) => order._id === orderId)[0];

        setData({
            ...data,
            selectedOrder: selectedOrder
        })

        const detailsPanelEL = document.getElementById('details-panel') ? document.getElementById('details-panel') : null;
        // make css class
        if (detailsPanelEL) {
            detailsPanelEL.style.width = '530px';
            detailsPanelEL.style.paddingLeft = '20px';
            detailsPanelEL.style.color = '#000000';
        }
    }

    const handleSeeMoreClick = (orderId: string) => () => {
        showDetailsPanel(orderId);
    }

    /*const getDateColumnValue = (order: PurchaseOrder): string => {
        if (order.details == null || order.details.timestampIn == null) {
            return 'N/A';
        }

        const orderDate = new Date(order.details.timestampIn);
        const month = new Intl.DateTimeFormat('en', {month: 'short'}).format(orderDate);
        const day = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(orderDate);
        const hour = new Intl.DateTimeFormat('en', {hour:'numeric', hour12:false}).format(orderDate);
        const minute = new Intl.DateTimeFormat('en', {minute: 'numeric'}).format(orderDate);
        const parsedMinute = parseInt(minute) <= 9 ? '0' + minute : minute;

        return `${day} ${month} ${hour}:${parsedMinute}`;
    }*/ 

    const formatAndSetLoadingDate = (order: PurchaseOrder): string => {
        if (order.details == null || order.details.loadingDate == null) {
            return 'N/A';
        }

        const loadingDate = order.details.loadingDate.replaceAll('-', ' ');
        return loadingDate;
    }

    const tableContent: Array<ReactElement | void> = data.purchaseOrders.map((order: PurchaseOrder) => {

        return (
            <tr key={order._id} className='purchase-orders-table-body-row'>
            <td className='orders-reports-table-body-cell orders-cell'>{formatAndSetLoadingDate(order)}</td>
            <td className='orders-reports-table-body-cell orders-cell eclipse-text' title={`${order.client.name}`}>{order.client.name}</td>
            <td className='orders-reports-table-body-cell orders-cell eclipse-text' title={`${order.productName}`}>{order.productName}</td>
            <td className='orders-reports-table-body-cell orders-cell'>{order.details?.licencePlateTruck ? order.details.licencePlateTruck : 'N/A'}</td>
            <td className={`orders-reports-table-body-cell orders-cell ${Utils.setColorByState(order.status)}`}>{order.status}</td>
            <td className='orders-reports-table-body-cell orders-cell'>{order.orderedQuantity}</td>
            <td className='orders-reports-table-body-cell orders-cell'><img className='purchase-orders-show-more-icon' src={seeMoreIcon} alt='see-more' onClick={handleSeeMoreClick(order._id)}/></td>
        </tr>
        )
    });

    return (
        <div className='etportal-page-container'>
            <h1 className='module-title'>Purchase Orders</h1>
            <PurchaseOrdersSidePanel purchaseOrder={data.selectedOrder!} reloadOrders={getPurchaseOrders}/>
            <div id='table-container'>
                <table id='purchase-orders-table' className='header-fixed'>
                    <thead id='purchase-orders-table-head'>
                        <tr className='purchase-orders-table-head-row'>
                            <th className='orders-reports-table-head-cell orders-cell'>
                                Date
                            </th>
                            <th className='orders-reports-table-head-cell orders-cell'>
                                Client Name
                            </th>
                            <th className='orders-reports-table-head-cell orders-cell'>
                                Product Name
                            </th>
                            <th className='orders-reports-table-head-cell orders-cell'>
                                Truck Plate
                            </th>
                            <th className='orders-reports-table-head-cell orders-cell'>
                                Status
                            </th>
                            <th className='orders-reports-table-head-cell orders-cell'>
                                Ordered Quantity
                            </th>
                            <th className='orders-reports-table-head-cell'>
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody>{tableContent}</tbody>
                </table>
            </div>
        </div>
    )
}

export default PurchaseOrdersPage;