import logOutIco from '../../../images/logout_ico.png';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../../redux/actions/auth';

import './logOut.styles.css';
import { RootState } from '../../../redux/types';

const LogOut = () => {
    const dispatch = useDispatch();
    const userSessionId = useSelector((state: RootState) => state.auth.sessionId);

    const logOut = () => {
        if (userSessionId) {
            dispatch(logout(userSessionId));
        }
    }

    return (
        <div id='logout-container' onClick={logOut}>
            <img id='logout-icon' src={logOutIco} alt='logout-icon'/>
            <p id='logout-text'>LogOut</p>
        </div>
    )
}
export default LogOut;