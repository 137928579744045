import { ClientDetailedAddress, Company } from "../../../../lib/types";
import CompanyProfilePageDataProvider from "../dataProvider/companyProfilePage.dataProvider";
import { WorkPoint } from "../types/types";

export default class CompanyProfilePageController {
    private dataProvider: CompanyProfilePageDataProvider = new CompanyProfilePageDataProvider();

    public async getCompanyData(): Promise<Company> {
        return await this.dataProvider.getCompanyData();
    }

    public async updateCompanyData(regComNo: string, address: string, iban: string, bankName: string, detailedAddress: ClientDetailedAddress, vatOnPayment: boolean, vatPayer: boolean): Promise<void> {
        await this.dataProvider.updateCompanyData(regComNo, address, iban, bankName, detailedAddress, vatOnPayment, vatPayer);
    }

    public async getCompanyWorkPoints(): Promise<WorkPoint[]> {
        return await this.dataProvider.getCompanyWorkPoints();
    }

    public async updateCompanyWorkPoint(workPoint: WorkPoint): Promise<void> {
        await this.dataProvider.updateCompanyWorkPoint(workPoint._id, workPoint.name!);
    }

    public async uploadCompanyLogo(fileData: string | ArrayBuffer | null, fileType: string): Promise<void> {
        await this.dataProvider.uploadCompanyLogo(fileData, fileType);
    }

    public async deleteCompanyLogo(): Promise<void> {
        await this.dataProvider.deleteCompanyLogo();
    }
}