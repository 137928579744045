import { Utils } from '../../../../../lib/utils/utils';
import { EFacturaProcessStatus, Invoice, InvoiceCancellationStatus } from '../../types/types';
import uploadFile from '../../../../../images/upload.png';
import downloadImg from '../../../../../images/download.png';
import generatePdf from '../../../../../images/icons8-pdf-16.png';
import reloadGif from '../../../../../images/reload-gif.gif';
import failImg from '../../../../../images/x-mark.png';
import editImg from '../../../../../images/edit-icon.png';
import moneyImg from '../../../../../images/icons8-money-24.png';
import cancelImg from '../../../../../images/cancel-icon.png';
import greenMoneyImg from '../../../../../images/icons8-money-24-green.png';
import './invoiceTableRow.styles.css';
import { InvoiceTableRowController } from './controller/invoiceTableRow.controller';
import { Component } from 'react';
import { UploadInvoiceToEFacturaResponse } from './types/invoiceTableRow.types';
import { PdfBuilderFactory, PdfDocumentType } from '../../../../../lib/pdfBuilder/pdfBuilderFactory';
import { ETIcon } from '../../../../ETComponents/ETIcon/eticon.component';
import { PopupButtonTypes } from '../../../../ETComponents/ETPopup/constants';
import { dispatchPopupEvent } from '../../../../../lib/events/popupEvent';
import { dispatchRequestErrorEvent } from '../../../../../lib/events/requestErrorEvent';

interface InvoiceTableRowProps {
    invoice: Invoice;
    displayPaymentModal: (invoice: Invoice) => void;
    editInvoice: (invoice: Invoice) => void;
    updateInvoice: (invoice: Invoice) => void;
    invoiceInUpdateForm: Invoice | null;
    reloadInvoices: () => Promise<void>;
}

interface InvoiceTableRowState {
    isUploadButtonHidden: boolean;
    isLoadingButtonHidden: boolean;
    isDownloadButtonHidden: boolean;
    isFailButtonHidden: boolean;
    isEditButtonHidden: boolean;
    isCancelButtonHidden: boolean;
}

class InvoiceTableRow extends Component<InvoiceTableRowProps, InvoiceTableRowState> {
    private controller: InvoiceTableRowController;

    constructor(props: InvoiceTableRowProps) {
        super(props);

        this.controller = new InvoiceTableRowController();

        this.state = this.getDefaultState();

        this.handleUploadFileClick = this.handleUploadFileClick.bind(this);
        this.displayProperEFacturaAndEditIcons = this.displayProperEFacturaAndEditIcons.bind(this);
        this.displayUploadIconAfterApiError = this.displayUploadIconAfterApiError.bind(this);
        this.cancelInvoiceButtonClicked = this.cancelInvoiceButtonClicked.bind(this);
        this.handleInvoiceCancelation = this.handleInvoiceCancelation.bind(this);
    }

    private getDefaultState(): InvoiceTableRowState {
        return {
            isUploadButtonHidden: false,
            isLoadingButtonHidden: true,
            isDownloadButtonHidden: true,
            isFailButtonHidden: true,
            isEditButtonHidden: true,
            isCancelButtonHidden: false
        }
    }

    async componentDidMount() {
        if (!this.props.invoice.eFacturaData) return;
        await this.displayProperEFacturaAndEditIcons(this.props.invoice.eFacturaData.status, this.props.invoice._id);
    }

    private async handleUploadFileClick(invoiceId: string): Promise<void> {
        if(!!this.props.invoiceInUpdateForm && this.props.invoiceInUpdateForm._id === this.props.invoice._id) {
            dispatchPopupEvent('Atentie', 'Va rugam sa salvati modificarile inainte de a trimite in EFactura.');
            return;
        }

        if (!this.state.isUploadButtonHidden) {
            this.setState({
                isUploadButtonHidden: true,
                isEditButtonHidden: true
            });
        }

        if (this.state.isLoadingButtonHidden) {
            this.setState({
                isLoadingButtonHidden: false,
            });
        }

        this.setInvoiceUploadStatus(EFacturaProcessStatus.UPLOADING);

        let uploadResult: UploadInvoiceToEFacturaResponse = { success: false };
        let shouldAllowReUpload: boolean = false;
        try {
            uploadResult = await this.controller.uploadInvoiceToEFactura(invoiceId);
        } catch (err: any) {
            dispatchRequestErrorEvent(err);
        }

        if(shouldAllowReUpload) {
            this.displayUploadIconAfterApiError();
        } else {
            this.displayProperIconsAfterUpload(uploadResult);
        }
    }

    private displayProperIconsAfterUpload(uploadResult: UploadInvoiceToEFacturaResponse) {
        if (!this.state.isLoadingButtonHidden) {
            this.setState({
                isLoadingButtonHidden: true
            });
        }

        if (uploadResult.success) {
            if (this.state.isDownloadButtonHidden) {
                this.setState({
                    isDownloadButtonHidden: false,
                    isEditButtonHidden: true
                });
            }
            this.setInvoiceUploadStatus(EFacturaProcessStatus.UPLOAD_OK)
        } else {
            if (this.state.isFailButtonHidden) {
                this.setState({
                    isFailButtonHidden: false,
                    isEditButtonHidden: false
                });
            }
            this.setInvoiceUploadStatus(EFacturaProcessStatus.INVALID_XML)
        }
    }

    private displayUploadIconAfterApiError() {
        this.setState({
            isLoadingButtonHidden: true,
            isDownloadButtonHidden: true,
            isFailButtonHidden: true,
            isUploadButtonHidden: false
        });
    }

    private setInvoiceUploadStatus(status: EFacturaProcessStatus) {
        this.props.invoice.eFacturaData.status = status;
        this.props.updateInvoice(this.props.invoice);
    }

    private async waitForInvoiceUploadToFinish(invoiceId: string) {
        let uploadResult: UploadInvoiceToEFacturaResponse = { success: false };
        try {
            uploadResult = await this.controller.waitForInvoiceUploadToFinish(invoiceId);
        } catch (err: any) {
            dispatchRequestErrorEvent(err);
        }

        this.displayProperIconsAfterUpload(uploadResult);
    }

    private async displayProperEFacturaAndEditIcons(invoiceStatus: EFacturaProcessStatus, invoiceId: string) {
        switch (invoiceStatus) {
            case EFacturaProcessStatus.INVALID_XML:
                this.setState({
                    isDownloadButtonHidden: true,
                    isLoadingButtonHidden: true,
                    isUploadButtonHidden: true,
                    isFailButtonHidden: false,
                    isEditButtonHidden: false
                });
                break;

            case EFacturaProcessStatus.UNSYNCED:
                this.setState({
                    isDownloadButtonHidden: true,
                    isLoadingButtonHidden: true,
                    isUploadButtonHidden: false,
                    isFailButtonHidden: true,
                    isEditButtonHidden: false
                });
                break;

            case EFacturaProcessStatus.UPLOADING:
                this.setState({
                    isDownloadButtonHidden: true,
                    isLoadingButtonHidden: false,
                    isUploadButtonHidden: true,
                    isFailButtonHidden: true,
                    isEditButtonHidden: true
                }, async () => {
                    await this.waitForInvoiceUploadToFinish(invoiceId);
                });
                break;

            case EFacturaProcessStatus.UPLOAD_OK:
                this.setState({
                    isDownloadButtonHidden: false,
                    isLoadingButtonHidden: true,
                    isUploadButtonHidden: true,
                    isFailButtonHidden: true,
                    isEditButtonHidden: true
                });
                break;

            case EFacturaProcessStatus.UPLOAD_NOK:
                this.setState({
                    isDownloadButtonHidden: true,
                    isLoadingButtonHidden: true,
                    isUploadButtonHidden: true,
                    isFailButtonHidden: false,
                    isEditButtonHidden: false
                });
                break;

            default:
                this.setState({
                    isDownloadButtonHidden: true,
                    isLoadingButtonHidden: false,
                    isUploadButtonHidden: true,
                    isFailButtonHidden: true,
                    isEditButtonHidden: true
                });
                break;
        }

        this.setState({
            isCancelButtonHidden: this.props.invoice.invoiceCancellationInfo !== undefined && this.props.invoice.invoiceCancellationInfo.status !== InvoiceCancellationStatus.NORMAL ? true : false
        });
    }

    private handleGeneratePdfClick = () => (): void => {
        PdfBuilderFactory.getPdfBuilder(PdfDocumentType.Invoice).downloadDocument(this.props.invoice);
    }

    private async handleDownloadButtonClicked(downloadId: string) {
        try {
            const data = await this.controller.downloadANAFInvoiceZip(downloadId);

            if(data.success){
                const url = window.URL.createObjectURL(data.data!);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${data.filename}`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        } catch (err: any) {
            dispatchRequestErrorEvent(err);
        }
    }

    private async cancelInvoiceButtonClicked() {
        dispatchPopupEvent(
            'Atentie', 
            `Sunteti sigur ca doriti sa stornati factura ${this.props.invoice.invoiceHeaderInfo.series}${this.props.invoice.invoiceHeaderInfo.number}?`,
            false,
            PopupButtonTypes.YES_NO,
            async () => {
                await this.handleInvoiceCancelation();
            }
        );
    } 
    
    private async handleInvoiceCancelation() {
        try {
            //create the "storno" invoice
            await this.controller.cancelInvoice(this.props.invoice._id);

            //reload invoices
            await this.props.reloadInvoices();
        } catch(err: any) {
            dispatchRequestErrorEvent(err);
        }
    }

    render() {
        const {invoice} = this.props;
        const invoiceDate = new Date(invoice.invoiceHeaderInfo.date);
        const { year, month, day } = Utils.getDateElements(invoiceDate);

        const invoiceDueDate = new Date(invoice.invoiceHeaderInfo.dueDate);
        const dueDateElements = Utils.getDateElements(invoiceDueDate);

        return (
            <tr key={invoice._id} className='invoice-table-body-row'>
                <td className='orders-reports-table-body-cell invoice-cell-small'>
                    <div className="display-flex">
                        <ETIcon image={generatePdf} altText={'print'} tooltipText={'Descarca PDF'} onClick={this.handleGeneratePdfClick()}/>
                        <ETIcon image={editImg} altText={'edit'} isHidden={this.state.isEditButtonHidden} tooltipText={'Modifica factura'} onClick={() => this.props.editInvoice(this.props.invoice)}/>
                    </div>
                </td>
                <td className='orders-reports-table-body-cell invoice-cell-small'>{invoice.invoiceHeaderInfo.number}</td>
                <td className='orders-reports-table-body-cell invoice-cell'>{`${day}/${month}/${year}`}</td>
                <td className='orders-reports-table-body-cell invoice-cell eclipse-text' title={`${invoice.invoiceHeaderInfo.clientData.name}`}>{invoice.invoiceHeaderInfo.clientData.name}</td>
                <td className='orders-reports-table-body-cell invoice-cell'>{`${dueDateElements.day}/${dueDateElements.month}/${dueDateElements.year}`}</td>
                <td className='orders-reports-table-body-cell invoice-cell'>{invoice.details.netAmount}</td>
                <td className='orders-reports-table-body-cell invoice-cell'>{invoice.details.vatAmount}</td>
                <td className='orders-reports-table-body-cell invoice-cell'>{invoice.details.totalAmount}</td>
                <td className='orders-reports-table-body-cell invoice-cell'>
                    <div className="display-flex">
                        <ETIcon image={uploadFile} altText={'upload'} isHidden={this.state.isUploadButtonHidden} tooltipText={'Trimite in SPV'} onClick={() => this.handleUploadFileClick(invoice._id)}/>
                        <ETIcon image={reloadGif} altText={'see-more'} isHidden={this.state.isLoadingButtonHidden} tooltipText={'Incarcare SPV in curs...'}/>
                        <ETIcon image={downloadImg} altText={'success'} isHidden={this.state.isDownloadButtonHidden} tooltipText={'Descarca arhiva SPV'} onClick={() => this.handleDownloadButtonClicked(invoice.eFacturaData.downloadId)}/>
                        <ETIcon image={failImg} altText={'fail'} isHidden={this.state.isFailButtonHidden} tooltipText={'Erori XML SPV'}/>
                        <ETIcon image={this.props.invoice.paymentId ? greenMoneyImg : moneyImg} altText={'pay-invoice'} tooltipText={!this.props.invoice.paymentId ? 'Adauga incasare' : 'Vezi incasarea'} onClick={() => this.props.displayPaymentModal(this.props.invoice)}/>
                        <ETIcon image={cancelImg} altText={'cancel'} isHidden={this.state.isCancelButtonHidden} tooltipText={'Storneaza factura'} onClick={() => this.cancelInvoiceButtonClicked()}/>
                    </div>
                </td>
            </tr>
        )
    }
};

export default InvoiceTableRow;
