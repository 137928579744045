import { Component } from "react";
import { connect } from "react-redux";
import { AnafOauthRequest } from "../../../lib/serverRequests/anafOauthRequests/AnafOauthRequest";
import './anafOauthPage.styles.css';
import AnafTokenForm from "./innerComponents/anafTokenForm/anafTokenForm.component";
import { RootState } from "../../../redux/types";
import { TokenType } from "./types/types";
import { dispatchRequestErrorEvent } from "../../../lib/events/requestErrorEvent";
import { dispatchPopupEvent } from "../../../lib/events/popupEvent";

interface AnafOauthState {
    anafETransportAccessToken: string;
    shouldAutomaticallyRefreshETransportToken: boolean;
    anafEFacturaAccessToken: string;
    shouldAutomaticallyRefreshEFacturaToken: boolean;
}

interface AnafOauthProps {
    companyId: string;
}

class AnafOauthPage extends Component<AnafOauthProps, AnafOauthState> {

    constructor(props: any) {
        super(props);

        this.state = {
            anafETransportAccessToken: '',
            shouldAutomaticallyRefreshETransportToken: false,
            anafEFacturaAccessToken: '',
            shouldAutomaticallyRefreshEFacturaToken: false
        };

        this.handleCheckChangeETransport = this.handleCheckChangeETransport.bind(this);
        this.handleCheckChangeEFactura = this.handleCheckChangeEFactura.bind(this);
        this.launchAnafRequestForETransportToken = this.launchAnafRequestForETransportToken.bind(this);
        this.launchAnafRequestForEFacturaToken = this.launchAnafRequestForEFacturaToken.bind(this);
    }

    async componentDidMount(): Promise<void> {
        await this.getANAFAccessTokens();
    }

    private async getANAFAccessTokens(): Promise<void> {
        try {
            const tokensData = await AnafOauthRequest.getAnafOauthToken();

            this.setState({
                ...this.state,
                anafETransportAccessToken: tokensData.eTransportAccessToken,
                shouldAutomaticallyRefreshETransportToken: tokensData.shouldAutomaticallyRefreshETransportToken,
                anafEFacturaAccessToken: tokensData.eFacturaAccessToken,
                shouldAutomaticallyRefreshEFacturaToken: tokensData.shouldAutomaticallyRefreshEFacturaToken,
            });
        } catch (err: any) {
            dispatchRequestErrorEvent(err);
        }
    }

    private launchAnafRequestForETransportToken() {
        const client_id = '125eef412e04c1099866bc0c13eb0023e996dd8295b89b64';
        const redirect_uri = 'https://www.portal-evertech.com/publicApi/anafToken';

        this.launchAnafRequest(client_id, redirect_uri);
    }

    private launchAnafRequestForEFacturaToken() {
        const client_id = '6b8c73bbc465d91fb2569309a54b7e8a7e3ee71d04b17665';
        const redirect_uri = 'https://www.portal-evertech.com/publicApi/anafTokenEFactura';

        this.launchAnafRequest(client_id, redirect_uri);
    }
    
    private launchAnafRequest(clientId: string, redirectUri: string) {
        const grant_type = 'code';
        const auth_endpoint = 'https://logincert.anaf.ro/anaf-oauth2/v1/authorize';
        const state = this.props.companyId;
        const token_content_type = 'jwt';
        const auth_url = `${auth_endpoint}?response_type=${grant_type}&client_id=${clientId}&redirect_uri=${redirectUri}&token_content_type=${token_content_type}&state=${state}`;

        window.open(auth_url);
    }

    private async handleCheckChangeETransport(): Promise<void> {
        this.setState({
            ...this.state,
            shouldAutomaticallyRefreshETransportToken: !this.state.shouldAutomaticallyRefreshETransportToken
        }, async () => {
            const updateSuccessful = await AnafOauthRequest.updateAutomaticallyRefreshSetting({ automaticallyRefreshETransportToken: this.state.shouldAutomaticallyRefreshETransportToken });
            this.treatUpdateResponse(updateSuccessful, TokenType.E_TRANSPORT, this.state.shouldAutomaticallyRefreshETransportToken);
        });
    }

    private async handleCheckChangeEFactura(): Promise<void> {
        this.setState({
            ...this.state,
            shouldAutomaticallyRefreshEFacturaToken: !this.state.shouldAutomaticallyRefreshEFacturaToken
        }, async () => {
            const updateSuccessful = await AnafOauthRequest.updateAutomaticallyRefreshSetting({ automaticallyRefreshEFacturaToken: this.state.shouldAutomaticallyRefreshEFacturaToken });
            this.treatUpdateResponse(updateSuccessful, TokenType.E_FACTURA, this.state.shouldAutomaticallyRefreshEFacturaToken);
        });
    }

    private treatUpdateResponse(updateSuccessful: boolean, spvSection: TokenType, shouldRefreshActive: boolean) {
        let message = 'Nu s-a putut salva optiunea de prelungire automata. Va rugam incercati din nou mai tarziu.';

        if (!updateSuccessful) {
            dispatchPopupEvent('Eroare', message);

            if (spvSection === TokenType.E_TRANSPORT) {
                this.setState({
                    ...this.state,
                    shouldAutomaticallyRefreshETransportToken: !this.state.shouldAutomaticallyRefreshETransportToken
                });
            } else {
                this.setState({
                    ...this.state,
                    shouldAutomaticallyRefreshEFacturaToken: !this.state.shouldAutomaticallyRefreshEFacturaToken
                });
            }
            return;
        }

        message = shouldRefreshActive
        ? `Optiunea de prelungire automata pentru autentificarea ${spvSection} a fost activata cu succes.`
        : `Ati dezactivat prelungirea automata a autentificarii ${spvSection}.`
        dispatchPopupEvent('Success', message);
    }

    render() {
        return (
            <div className='etportal-page-container'>
                <h1 className='module-title'>Autentificare SPV ANAF</h1>
                <AnafTokenForm title='Autentificare eTransport' launchAnafRequest={this.launchAnafRequestForETransportToken} handleCheckChange={this.handleCheckChangeETransport} token={this.state.anafETransportAccessToken} automaticallyRefresh={this.state.shouldAutomaticallyRefreshETransportToken} />
                <AnafTokenForm title='Autentificare eFactura' launchAnafRequest={this.launchAnafRequestForEFacturaToken} handleCheckChange={this.handleCheckChangeEFactura} token={this.state.anafEFacturaAccessToken} automaticallyRefresh={this.state.shouldAutomaticallyRefreshEFacturaToken}/>
            </div>
        )
    }
    
}

const mapStateToProps = (rootState: RootState) => {
    return {
        companyId: rootState.auth.company.id
    }
}

export default connect(mapStateToProps)(AnafOauthPage);