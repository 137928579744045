import { ScaleReport } from "../../../../lib/types";
import { TimeRange } from "../../../ETComponents/EtTimeRangeFilter/etTimeRangeFilter.types";

export enum FILTER_NAMES {
    SUPPLIER = 'supplier',
    CLIENT = 'client',
    PRODUCT = 'product',
}

export interface IFilter {
    visible: boolean;
    appliedValue: string;
}

export type Filters  = {
    [name in FILTER_NAMES]: IFilter;
}

export interface ScaleReportsState {
    scaleReports: Array<ScaleReport>;
    selectedReport: ScaleReport | null;
    viewId: string;
    filters: Filters;
}

export interface ScaleReportsView {
    viewId: string;
    filters: Array<FilterForBackend>;
}

export interface FilterForBackend {
    name: FILTER_NAMES;
    values: Array<string>;
}

export interface TimeRangeFilter {
    name: string;
    values: TimeRange;
}

export interface RequestControllerResponse {
    valid: boolean;
    errorCode?: number;
    errorMessage?: string;
}

export interface LoadViewResponse extends RequestControllerResponse {
    view?: ScaleReportsView;
    transformedViewFilters?: Filters;
}

export interface GetReportsResponse extends RequestControllerResponse {
    scaleReports?: Array<ScaleReport>
}

export enum FilterTypes {
    suppliers = 'suppliers',
    clients = 'clients',
    transporters = 'transporters',
    products = 'products',
    workPoints = 'workPoints'
}

export enum ScaleReportFields {
    supplierName = 'supplierName',
    clientName = 'clientName',
    productName = 'productName',
    endpointId = 'endpointId',
}

export enum ScaleReportDetailsFields {
    transporterName = 'transporter'
}

export const FilterTypeToScaleReportFieldMap = new Map<FilterTypes, ScaleReportFields | ScaleReportDetailsFields>([
	[FilterTypes.suppliers, ScaleReportFields.supplierName],
	[FilterTypes.clients, ScaleReportFields.clientName],
    [FilterTypes.transporters, ScaleReportDetailsFields.transporterName],
	[FilterTypes.products, ScaleReportFields.productName],
    [FilterTypes.workPoints, ScaleReportFields.endpointId]
]);

export const FilterTypeToScaleReportDetailsFieldMap = new Map<FilterTypes, ScaleReportDetailsFields>([
	[FilterTypes.transporters, ScaleReportDetailsFields.transporterName],
]);