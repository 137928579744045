import { useDispatch } from 'react-redux';
import closeDetailsPanelIcon from '../../../images/close-arrow.png';
import { PurchaseOrder } from "../../../lib/types";

import './purchaseOrdersSidePanel.style.css';
import { displayPopup } from '../../../redux/actions/popup';
import { PopupButtonTypes } from '../../ETComponents/ETPopup/constants';
import { PurchaseOrderRequest } from '../../../lib/serverRequests/purchaseOrderRequests/PurchaseOrderRequest';
import { RequestError } from '../../../lib/serverRequests/ServerRequest';
import { Utils } from '../../../lib/utils/utils';

interface PurchaseOrdersSidePanelProps {
    purchaseOrder: PurchaseOrder;
    reloadOrders: () => void;
}

const PurchaseOrdersSidePanel = (props: PurchaseOrdersSidePanelProps) => {

    const dispatch = useDispatch();

    let shouldReloadOrdersTableOnClose: boolean = false;

    const closeDetailsPanel = () => {
        if (shouldReloadOrdersTableOnClose) {
            props.reloadOrders();
        }

        const detailsPanelEL = document.getElementById('details-panel') ? document.getElementById('details-panel') : null;
        if (detailsPanelEL) {
            detailsPanelEL.style.width = '0px';
            detailsPanelEL.style.paddingLeft = '0px';
            detailsPanelEL.style.color = '#ffffff';
        }
    }

    const cancelPurchaseOrder = () => {
        dispatch(displayPopup(
            'Cancel order',
            `Are you sure you want to cancel order no: ${props.purchaseOrder.orderNumber}?`,
            PopupButtonTypes.YES_NO,
            false,
            async () => {
                if (!props.purchaseOrder) {
                    return;
                }
                let cancelSuccessful: boolean = true;

                try {
                    cancelSuccessful = await PurchaseOrderRequest.cancelPurchaseOrder(props.purchaseOrder._id);
                } catch (err: RequestError | any) {
                    if ((err as RequestError).statusCode === 401) {
                        dispatch(displayPopup('OOps, looks like session problems!', (err as RequestError).message, PopupButtonTypes.OK, true));
                    } else {
                        dispatch(displayPopup('Error', 'Internal Server Error', PopupButtonTypes.OK, false));
                    }
                }

                if (cancelSuccessful) {
                    shouldReloadOrdersTableOnClose = true;
                    dispatch(displayPopup(
                        'Cancel successful',
                        `You have successfully cancelled the Order no. :${props.purchaseOrder.orderNumber}.`,
                        PopupButtonTypes.OK,
                        false
                    ));
                }
                
            },
            () => { }
        ));
    }

    return (
        <div id='details-panel'>
            <div id='order-details-panel-header'>
                <h3>Order Details</h3>
                <div id='status-container'>
                    <h3 id='report-status' className={`${props.purchaseOrder ? Utils.setColorByState(props.purchaseOrder.status) : ""}`}>{props.purchaseOrder ? props.purchaseOrder.status : ''}</h3>
                </div>
                <div id='close-details-panel-img-container'>
                    <img id='details-panel-close-icon' src={closeDetailsPanelIcon} alt='close-panel' onClick={closeDetailsPanel}/>
                </div>
            </div>
            
            <div id='report-details-section'>
                <p className='scale-detail'>Order Number: <span className='info'>{props.purchaseOrder ? props.purchaseOrder.orderNumber : ''}</span></p>
                <div id='details-table'>
                    <div id='details-table-column-1'>
                        <p className='scale-detail'>Supplier Name: <span className='info'>{props.purchaseOrder ? props.purchaseOrder.supplierName : ''}</span></p>
                        <p className='scale-detail'>Client Name: <span className='info'>{props.purchaseOrder ? props.purchaseOrder.client.name : ''}</span></p>
                        <p className='scale-detail'>Product: <span className='info'>{props.purchaseOrder ? props.purchaseOrder.productName : ''}</span></p>
                        <p className='scale-detail'>Ordered Quantity: <span className='info'>{props.purchaseOrder ? props.purchaseOrder.orderedQuantity : ''}</span> </p>
                        <p className='scale-detail'>Gross Quantity: <span className='info'>{(props.purchaseOrder && props.purchaseOrder.details?.grossQuantity) ? props.purchaseOrder.details.grossQuantity : 'N/A'}</span></p>
                        <p className='scale-detail'>Net Quantity: <span className='info'>{(props.purchaseOrder && props.purchaseOrder.details?.netQuantity) ? props.purchaseOrder.details.netQuantity : 'N/A'}</span></p>
                        <p className='scale-detail'>Date In: <span className='info'>{(props.purchaseOrder && props.purchaseOrder.details?.timestampIn) ? new Date(props.purchaseOrder.details.timestampIn).toLocaleString() : 'N/A'}</span></p>
                        <p className='scale-detail'>Date Out: <span className='info'>{(props.purchaseOrder && props.purchaseOrder.details?.timestampOut) ? new Date(props.purchaseOrder.details.timestampOut).toLocaleString() : 'N/A'}</span></p>
                    </div>
                    <div id='details-table-column-2'>
                        <p className='scale-detail'>Licence Plate Truck: <span className='info'>{(props.purchaseOrder && props.purchaseOrder.details?.licencePlateTruck) ? props.purchaseOrder.details.licencePlateTruck : 'N/A'}</span></p>
                        <p className='scale-detail'>Licence Plate Trail: <span className='info'>{(props.purchaseOrder && props.purchaseOrder.details?.licencePlateTrail) ? props.purchaseOrder.details.licencePlateTrail : 'N/A'}</span></p>
                        <p className='scale-detail'>Driver Name: <span className='info'>{(props.purchaseOrder && props.purchaseOrder.details?.driverName) ? props.purchaseOrder.details.driverName : 'N/A'}</span></p>
                        <p className='scale-detail'>Driver ID: <span className='info'>{(props.purchaseOrder && props.purchaseOrder.details?.driverIDNumber) ? props.purchaseOrder.details.driverIDNumber : 'N/A'}</span></p>
                        <p className='scale-detail'>Attach Document Number: <span className='info'>{(props.purchaseOrder && props.purchaseOrder.details?.attachDocumentNumber) ? props.purchaseOrder.details.attachDocumentNumber : 'N/A'}</span></p>
                        <p className='scale-detail'>Seal: <span className='info'>{(props.purchaseOrder && props.purchaseOrder.details?.seal) ? props.purchaseOrder.details.seal : 'N/A'}</span></p>
                        <p className='scale-detail'>Lot: <span className='info'>{(props.purchaseOrder) ? props.purchaseOrder.lot : 'N/A'}</span></p>
                    </div>
                </div>
                <div id='button-container'>
                    <button className='et-button-danger' id='cancel-order-button' onClick={cancelPurchaseOrder}>Cancel Order</button>
                </div>
            </div>
        </div>
    )
}

export default PurchaseOrdersSidePanel;