import { useDispatch, useSelector } from "react-redux";

import { CONSTANTS } from "../../../lib/constants";
import { setActiveModule } from "../../../redux/actions/application";
import MenuListItem from "../../lib/menuListItem/menuListItem.component";
import  Profile  from "../../lib/profile/profile.component";
import Logo from "../../lib/logo/logo.component";
import LogOut from "../../lib/logOut/logOut.component";
import { RootState } from "../../../redux/types";

import ProfileIcon from '../../../images/profileTest.png';
import AdminPanelIcon from '../../../images/adminPanel.png';
import ScaleReportsIcon from '../../../images/scaleReports.png';
import PurchaseOrdersIcon from '../../../images/purchaseOrders.png';
import './menu.styles.css';

export interface Module {
    moduleName: string;
    requiredRoles: Array<string>;
    requiredFeatures: Array<number>;
    menuName?: string;
    icon?: any;
    key: string;
    isMenuItem: boolean;
}

export const modules: Array<Module> = [
    {
        moduleName: CONSTANTS.MODULES.PROFILE_PAGE,
        requiredRoles: [CONSTANTS.USER_ROLES.SUPERADMIN, CONSTANTS.USER_ROLES.ADMIN],
        requiredFeatures: [],
        menuName: 'Profil',
        icon: ProfileIcon,
        key: 'profile_key',
        isMenuItem: true
    },
    {
        moduleName: CONSTANTS.MODULES.SUPERADMIN_PAGE,
        requiredRoles: [CONSTANTS.USER_ROLES.SUPERADMIN],
        requiredFeatures: [],
        menuName: 'Panou admin',
        icon: AdminPanelIcon,
        key: 'admin_panel_key',
        isMenuItem: true
    },
    {
        moduleName: CONSTANTS.MODULES.SCALE_REPORTS_PAGE,
        requiredRoles: [CONSTANTS.USER_ROLES.SUPERADMIN, CONSTANTS.USER_ROLES.ADMIN],
        requiredFeatures: [],
        menuName: 'Rapoarte de cantarire',
        icon: ScaleReportsIcon,
        key: 'scale_reports_key',
        isMenuItem: true
    }, 
    {
        moduleName: CONSTANTS.MODULES.PURCHASE_ORDERS_PAGE,
        requiredRoles: [CONSTANTS.USER_ROLES.SUPERADMIN, CONSTANTS.USER_ROLES.ADMIN],
        requiredFeatures: [CONSTANTS.FEATURES.PURCHASE_ORDERS],
        menuName: 'Purchase Orders',
        icon: PurchaseOrdersIcon,
        key: 'purchase_orders_key',
        isMenuItem: true
    },
    {
        moduleName: CONSTANTS.MODULES.WASTE_MANAGEMENT_PAGE,
        requiredRoles: [CONSTANTS.USER_ROLES.SUPERADMIN, CONSTANTS.USER_ROLES.ADMIN],
        requiredFeatures: [CONSTANTS.FEATURES.WASTE_MANAGEMENT],
        menuName: 'Administrarea deseurilor',
        icon: PurchaseOrdersIcon,
        key: 'waste_management_key',
        isMenuItem: true
    },
    {
        moduleName: CONSTANTS.MODULES.ANAF_OAUTH_PAGE,
        requiredRoles: [CONSTANTS.USER_ROLES.SUPERADMIN, CONSTANTS.USER_ROLES.ADMIN],
        requiredFeatures: [CONSTANTS.FEATURES.SPV_INTEGRATION],
        menuName: 'Autentificare ANAF',
        icon: PurchaseOrdersIcon,
        key: 'anaf_oauth_key',
        isMenuItem: true
    },
    {
        moduleName: CONSTANTS.MODULES.INVOICE_PAGE,
        requiredRoles: [CONSTANTS.USER_ROLES.SUPERADMIN, CONSTANTS.USER_ROLES.ADMIN],
        requiredFeatures: [CONSTANTS.FEATURES.SPV_INTEGRATION],
        menuName: 'Facturare (BETA)',
        icon: ScaleReportsIcon,
        key: 'invoice_key',
        isMenuItem: true
    },
    {
        moduleName: CONSTANTS.MODULES.COMPANY_PROFILE_PAGE,
        requiredRoles: [CONSTANTS.USER_ROLES.SUPERADMIN, CONSTANTS.USER_ROLES.ADMIN],
        requiredFeatures: [],
        key: 'company_profile_key',
        isMenuItem: false
    }
];

const Menu = () => {
    const dispatch = useDispatch();
    const userName = useSelector((state: RootState) => state.auth.user.name);
    const userRole = useSelector((state: RootState) => state.auth.user.role);
    const companyName = useSelector((state: RootState) => state.auth.company.name);
    const companyFeatures = useSelector((state: RootState) => state.auth.company.features);

    const displayAvailableModules = (): Array<JSX.Element> =>  {
        const menuElements: Array<JSX.Element> = [];
        for (const module of modules) {
            const userHasRequiredRole = module.requiredRoles.includes(userRole);
            const userCompanyHasRequiredFeature = module.requiredFeatures.every((feature) => (companyFeatures || [] as Array<number>).includes(feature));

            if (!userHasRequiredRole || !userCompanyHasRequiredFeature || !module.isMenuItem) {
                continue;
            }

            if (!module.menuName || !module.icon) {
                throw Error('Menu item is not correctly configured');
            }

            menuElements.push(<li key={module.key} className='menu-item' onClick={() => dispatch(setActiveModule(module.moduleName))}><MenuListItem name={module.menuName} icon={module.icon}/></li>)
        }

        return menuElements;
    };

    return (
        <div id='menu-container'> 
            <Logo />
            <Profile name={userName} companyName={companyName}/>
            <ul id='menu-list'>
                {displayAvailableModules()}
            </ul>
            <LogOut />
        </div>
    )
}


export default Menu;