import { BaseDataProvider } from "../../../../lib/baseDataProvider/base.dataProvider";
import { ClientDetailedAddress, Company } from "../../../../lib/types";
import { WorkPoint } from "../types/types";

export default class CompanyProfilePageDataProvider extends BaseDataProvider {
    public async getCompanyData(): Promise<Company> {
        const result = await this.launchGetRequest<Company>('api/companies/getUsersCompany');
        return result.data;
    }

    public async updateCompanyData(regComNo: string, address: string, iban: string, bankName: string, detailedAddress: ClientDetailedAddress, vatOnPayment: boolean, vatPayer: boolean) {
        await this.launchPostRequest('api/companies/updateCompanyData', { regComNo, address, iban, bankName, detailedAddress, vatOnPayment, vatPayer });
    }

    public async getCompanyWorkPoints(): Promise<WorkPoint[]> {
        const result = await this.launchGetRequest<WorkPoint[]>('api/endpoints/');
        return result.data;
    }

    public async updateCompanyWorkPoint(workPointId: string, workPointName: string): Promise<void> {
        await this.launchPostRequest('publicApi/endpoints/update', { payload: { endpointId: workPointId, endpointName: workPointName }});
    }

    public async uploadCompanyLogo(fileData: string | ArrayBuffer | null, fileType: string): Promise<void> {
        await this.launchPostRequest('api/companies/uploadIcon', { fileData, fileType });
    }

    public async deleteCompanyLogo(): Promise<void> {
        await this.launchPostRequest('api/companies/deleteIcon', { });
    }
}