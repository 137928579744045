import { ChangeEvent, Component } from "react";
import { Invoice } from "../../types/types";
import { InvoicePaymentState, PaymentType, PaymentData } from "./types/invoicePayment.types";
import DatePicker from 'react-datepicker';
import './invoicePayment.styles.css';
import { Utils } from "../../../../../lib/utils/utils";
import InvoicePaymentController from "./controller/invoicePayment.controller";
import { PdfBuilderFactory, PdfDocumentType } from "../../../../../lib/pdfBuilder/pdfBuilderFactory";
import { dispatchRequestErrorEvent } from "../../../../../lib/events/requestErrorEvent";

interface InvoicePaymentProps {
    invoice: Invoice | null;
    hidePaymentModal: () => void;
    updateInvoicePayment: (paymentId: string) => void;
}

export class InvoicePayment extends Component<InvoicePaymentProps, InvoicePaymentState> {
    private controller = new InvoicePaymentController();

    constructor(props: InvoicePaymentProps) {
        super(props);

        this.state = this.getEmptyState();

        this.getPaymentData = this.getPaymentData.bind(this);
        this.getEmptyPaymentData = this.getEmptyPaymentData.bind(this);
        this.handleInvoicePaymentTypeInputChanged = this.handleInvoicePaymentTypeInputChanged.bind(this);
        this.handleInvoicePaymentConfirmationClicked = this.handleInvoicePaymentConfirmationClicked.bind(this);
        this.handleInvoicePaymentCancellationClicked = this.handleInvoicePaymentCancellationClicked.bind(this);
        this.handlePrintInvoicePaymentClicked = this.handlePrintInvoicePaymentClicked.bind(this);
    }

    private getEmptyState(): InvoicePaymentState {
        return {
            paymentData: this.getEmptyPaymentData(),
            paymentSeries: {
                code: "",
                number: 0
            }
        }
    }

    private getEmptyPaymentData(): PaymentData {
        const today = new Date(Date.now());
        const { year, month, day } = Utils.getDateElements(today);

        return {
            _id: "0",
            totalValue: this.props.invoice?.details.totalAmount!,
            type: PaymentType.RECEIPT,
            number: 0,
            series: '',
            encashmentDate: `${year}-${month}-${day}`,
            creationDate: `${year}-${month}-${day}`
        }
    }

    async componentDidMount() {
        await this.loadPaymentSeriesData();
    }

    async componentDidUpdate(prevProps: InvoicePaymentProps) {
        //props changed, try to fetch invoice payment
        if(!this.props.invoice) {
            return;
        }

        if ((!prevProps.invoice && this.props.invoice) ||
        (prevProps.invoice && prevProps.invoice._id !== this.props.invoice?._id)) {
            this.getPaymentData();
        }
    }

    private async loadPaymentSeriesData(): Promise<void> {
        try {
            const paymentSeries = await this.controller.getPaymentSeriesData();

            paymentSeries.number += 1;

            this.setState({
                paymentSeries: paymentSeries
            });
        } catch (err: any) {
            dispatchRequestErrorEvent(err);
            return;
        }
        
    }

    private async getPaymentData(): Promise<void> {
        if(this.props.invoice) {
            //if current invoice has no paymentId, set component state to default
            if(!this.props.invoice.paymentId) {
                const paymentData = this.getEmptyPaymentData();

                paymentData.number = this.state.paymentSeries.number;
                paymentData.series = this.state.paymentSeries.code;

                this.setState({
                    paymentData: paymentData
                });
                return;
            }

            const paymentData = await this.controller.getInvoicePayment(this.props.invoice.paymentId);
            this.setState({
                paymentData: paymentData
            });
        }
    }

    private handleInvoicePaymentTypeInputChanged(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>): void {
        const { value } = event.target;

        const paymentData = this.state.paymentData;
        paymentData.type = value;

        this.setState({
            paymentData: paymentData
        })
    }

    private handleInvoicePaymentEnchashmentDateChanged(date: Date): void {
        const { year, month, day } = Utils.getDateElements(date);

        const paymentData = this.state.paymentData;
        paymentData.encashmentDate = `${year}-${month}-${day}`;

        this.setState({
            paymentData: paymentData
        })
    }

    private handleInvoicePaymentDateChanged(date: Date): void {
        //mock function as onChange is mandatory on DatePicker
        console.log(date);
    }

    private async handleInvoicePaymentConfirmationClicked(): Promise<void> {
        this.props.hidePaymentModal();

        let paymentId: string = "";

        try {
            paymentId = await this.controller.createInvoicePayment(this.state, this.props.invoice!._id);
        } catch (err: any) {
            dispatchRequestErrorEvent(err);
            return;
        }

        await this.loadPaymentSeriesData();

        //update entry on parent component with the received id
        this.props.updateInvoicePayment(paymentId);
    }

    private handleInvoicePaymentCancellationClicked(): void {
        this.props.hidePaymentModal();
        
        try {
            this.controller.deleteInvoicePayment(this.state, this.props.invoice!._id);
        } catch (err: any) {
            dispatchRequestErrorEvent(err);
            return;
        }

        this.props.updateInvoicePayment("");
    }

    private handlePrintInvoicePaymentClicked(): void {
        PdfBuilderFactory.getPdfBuilder(PdfDocumentType.Payment).downloadDocument(this.state, {invoice: this.props.invoice});
    }

    render() {
        return (
            <div id='inside-invoice-payment-modal'>
                <div id='invoice-payment-title'>
                    <label id='invoice-payment-title-label' className='invoice-payment-title'>
                        Incasare Factura - {this.props.invoice?.invoiceHeaderInfo.series + "" + this.props.invoice?.invoiceHeaderInfo.number}/{this.props.invoice?.invoiceHeaderInfo.date}
                    </label>
                </div>
                <div>
                    <label id='invoice-payment-total-label' className='invoice-payment-total'>
                        Suma totala:
                    </label>
                    <input
                        id='invoice-payment-total-input'
                        className='et-form-input invoice-text-input'
                        value={this.state.paymentData.totalValue}
                        required
                        name='totalAmount'
                        disabled
                    ></input>
                </div>
                <div>
                    <label id='invoice-payment-type-label' className='invoice-payment-type'>
                        Tip:
                    </label>
                    <select 
                        id="invoice-payment-type-select"
                        className='et-form-selector'
                        name='type'
                        value={this.state.paymentData.type}
                        onChange={this.handleInvoicePaymentTypeInputChanged}
                        disabled={this.props.invoice?.paymentId ? true : false}
                    >
                        <option value={PaymentType.RECEIPT}>Chitanta</option>
                        <option value={PaymentType.BANK}>Banca</option>
                    </select>
                </div>
                {this.state.paymentData.type === PaymentType.RECEIPT ?
                    <>
                        <div>
                            <label id='invoice-payment-number-label' className='invoice-payment-number'>
                                Numar:
                            </label>
                            <input
                                id='invoice-payment-number-input'
                                className='et-form-input invoice-text-input'
                                type='number'
                                disabled
                                value={this.state.paymentData.number}
                            ></input>
                        </div>
                        <div>
                            <label id='invoice-payment-number-label' className='invoice-payment-number'>
                                Serie:
                            </label>
                            <input
                                id='invoice-payment-series-input'
                                className='et-form-input invoice-text-input'
                                type='text'
                                disabled
                                value={this.state.paymentData.series}
                            ></input>
                        </div>
                    </>
                    :
                    <></>
                }
                <div>
                    <label id='invoice-payment-encashment-date-label' className='invoice-payment-encashment-date'>
                        Data incasarii:
                    </label>
                    <DatePicker
                        selected={new Date(this.state.paymentData.encashmentDate)}
                        onChange={(date: Date) => this.handleInvoicePaymentEnchashmentDateChanged(date)}
                        dateFormat="dd/MM/yyyy"
                        name='encashmentDate'
                        disabled={this.props.invoice?.paymentId ? true : false}
                    />
                </div>
                {this.state.paymentData._id !== "0" ?
                    <div>
                        <label id='invoice-payment-creation-date-label' className='invoice-payment-creation-date'>
                            Data document:
                        </label>
                        <DatePicker
                            selected={new Date(this.state.paymentData.creationDate)}
                            onChange={(date: Date) => this.handleInvoicePaymentDateChanged(date)}
                            dateFormat="dd/MM/yyyy"
                            name='documentDate'
                            disabled
                        />
                    </div>
                    :
                    <></>
                }
                <div>
                    {this.state.paymentData._id === "0" ?
                        <input
                            id='invoice-payment-confirm-input'
                            className='et-button'
                            type='button'
                            value='Confirma plata'
                            onClick={this.handleInvoicePaymentConfirmationClicked}
                        ></input>
                        : 
                        <>
                            {this.state.paymentData.type === PaymentType.RECEIPT ?
                                <input
                                    id='invoice-payment-print-input'
                                    className='et-button'
                                    type='button'
                                    value='Printeaza chitanta'
                                    onClick={this.handlePrintInvoicePaymentClicked}
                                ></input>
                                :
                                <></>
                            }
                            <input
                                id='invoice-payment-cancel-input'
                                className='et-button-danger'
                                type='button'
                                value='Anuleaza plata'
                                onClick={this.handleInvoicePaymentCancellationClicked}
                            ></input>
                        </>
                    }
                </div>
            </div>
        );
    }
}

export default InvoicePayment;